import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    position: 'sticky',
    width: '100%',
    boxSizing: 'border-box',
    padding: '40px 20px',
    background: '#FF7A01',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    '& svg ': {
      color: '#fff',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',

    '& span': {
      margin: 0,
      padding: 0,
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#fff',
      fontFamily: 'Montserrat',
      fontSize: '18px',
    },
    '& strong': {
      fontWeight: 400,
      color: '#fff',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      marginTop: '10px',
    },
  },
  infosHeader: {
    display: 'flex',
    '& svg': {
      maxHeight: '15px',
    },
  },
  logo: {
    marginRight: '15px',
    height: '70px',
    width: '70px',
    borderRadius: '50%',
  },
});

export default useStyles;
