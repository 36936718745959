/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import useStyles from './styles';

function Server({ open, handleClose }) {
  const classes = useStyles();

  const [ip, setIp] = useState('');
  const [port, setPort] = useState('');

  useEffect(() => {
    if (open) {
      const serverStorage = localStorage.getItem('server');
      const server = serverStorage ? JSON.parse(serverStorage) : null;
      if (server) {
        setIp(server.ip);
        setPort(server.port);
      }
    }
  }, [open]);

  const handleOnSubmit = async e => {
    e.preventDefault();

    const server = {
      ip,
      port,
    };

    localStorage.setItem('server', JSON.stringify(server));

    handleClose(false);
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={() => handleClose(false)}>
      <div className={classes.header}>
        <h1>servidor</h1>
      </div>
      <div className={classes.root}>
        <span>Informe o Ip e a Porta de acesso</span>
        <div>
          <form onSubmit={handleOnSubmit} className={classes.sign}>
            <input
              className={classes.input}
              type="text"
              placeholder="Ip"
              value={ip}
              onChange={event => setIp(event.target.value)}
            />
            <input
              className={classes.input}
              type="text"
              placeholder="Porta"
              value={port}
              onChange={event => setPort(event.target.value)}
            />
            <input className={classes.submit} type="submit" value="SALVAR" />
          </form>
        </div>
        <span>
          Para funcionar corretamente é necessario que o servidor esteja ligado
        </span>
      </div>
    </Drawer>
  );
}

export default Server;
