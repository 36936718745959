/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu } from '@material-ui/icons';
import logo from '../../../../assets/logo-white.png';

import useStyles from './styles';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const serverStorage = localStorage.getItem('server');
  const server = serverStorage ? JSON.parse(serverStorage) : null;
  const { ip, port } = server;

  const hanldeMenu = () => {
    dispatch({ type: '@data/MENU' });
  };

  return (
    <div className={classes.header}>
      <div className={classes.infosHeader}>
        <img src={logo} alt="comanda" className={classes.logo} />
        <div className={classes.info}>
          <span>comanda</span>
          <strong>{`${ip} | ${port}`}</strong>
        </div>
      </div>
      <Menu onClick={hanldeMenu} fontSize="large" />
    </div>
  );
}

export default Header;
