import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '70px',
    height: '70px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: props => props.comanda.background,
    color: props => props.comanda.color,
    fontSize: '18px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
}));

export default useStyles;
