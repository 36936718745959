import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '500px',
    fontFamily: 'Montserrat',
    background: '#fff',
    height: '100vh',
  },
  logo: {
    maxHeight: '60px',
  },
  sign: {
    marginTop: '40px',
  },
  input: {
    boxSizing: 'border-box',
    padding: '0 15px',
    borderRadius: '10px',
    border: 'none',
    background: '#eee',
    width: '100%',
    height: '60px',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: '15px',
    marginBottom: '20px',
  },
  submit: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    border: 'none',
    background: '#FF7A01',
    color: '#fff',
    width: '100%',
    height: '60px',
    fontWeight: 400,
    fontFamily: 'Montserrat',
    fontSize: '15px',
    letterSpacing: '1px',
    marginBottom: '20px',
  },
  btnServer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& img': {
      marginRight: '20px',
    },
    '& span': {
      fontSize: '15px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#c4c4c4',
    },
    '& span:hover': {
      color: '#FF7A01',
    },
  },
});

export default useStyles;
